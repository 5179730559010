import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import SectionTemplate from './SectionTemplate'

const windowAwait = typeof window !== 'undefined' ? window : ''
const documentAwait = typeof document !== 'undefined' ? document : ''

const ServicesSection = ({ expandedSection, onExpandedSectionChange, onMobileMenuClose }) => {

    const scrollToServicesSection = () => {
        const servicesTitle = documentAwait.getElementById('services-section-title')
        const servicesTitleY = servicesTitle.getBoundingClientRect().top + windowAwait.pageYOffset

        window.scrollTo({ top: servicesTitleY, behavior: 'smooth' })
    }

   const services = [
        {
            heading: `Let's Talk Offices`,
            description: ``,
        },
        {
            heading: `Client Spotlight`,
            description: ``,
        },
        {
            heading: `For Asset Owners`,
            description: ``,
        },
        {
            heading: `Your Office Options`,
            description: ``,
        },
        {
            heading: `Kontor FAQS`,
            description: ``,
        },
        {
            heading: `London Rentmap`,
            description: ``,
        },
    ]

    const handleServiceClick = (heading) => async (event) => {
        onExpandedSectionChange(null)(event)

        if (heading === `Let's Talk Offices`) {
            navigate('/knowledge-hub?lets-talk-offices')
            return
        }
        else if(heading === "Client Spotlight") {
            navigate('/knowledge-hub?client-spotlight')
            return
        }
        else if(heading === "For Asset Owners") {
            navigate('/knowledge-hub?for-asset-owners')
            return
        }
        else if(heading === "Your Office Options") {
            navigate('/knowledge-hub?your-office-options')
            return
        }
        else if(heading === "Kontor FAQS") {
            navigate('/knowledge-hub?kontor-faqs')
            return
        }
        else if(heading === "London Rentmap"){
            navigate('/london-rentmap')
            return
        }

        if (onMobileMenuClose) {
            onMobileMenuClose()
        }

        if (windowAwait.location.pathname !== '/') {
            await navigate('/')

            const timeout = setTimeout(() => {
                scrollToServicesSection()
                clearTimeout(timeout)
            }, 500)

            return
        }

        scrollToServicesSection()
    }

    return (
        <SectionTemplate
            name="Knowledge Hub"
            description={``}
            expandedSection={expandedSection}
            onExpandedSectionChange={onExpandedSectionChange}
        >
            {[services.slice(0, 2), services.slice(2, 4), services.slice(4, 6)].map((service) => (
                <div style={{ flex: '1 0 calc(25% - 4rem)' }} className="flex mx-8 my-4 md:m-8">
                    <div className="flex flex-col -m-4">
                        {service.map(({ heading, shortDescription, icon }) => (
                            <div
                                className="flex items-start m-2 cursor-pointer group"
                                onClick={handleServiceClick(heading)}
                            >
                                <div>
                                    <h6
                                        id={`s-${heading.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()}`}
                                        className="text-base font-bold"
                                    >
                                        <span className="inline-block">
                                            {heading}
                                            <span
                                                style={{ height: '2px' }}
                                                className="duration-300 ease-in-out bg-primary block w-0 group-hover:w-full"
                                            />
                                        </span>
                                    </h6>
                                    <p className="text-sm leading-tight mt-1 mb-0">{shortDescription}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </SectionTemplate>
    )
}

ServicesSection.propTypes = {
    expandedSection: PropTypes.string,
    onExpandedSectionChange: PropTypes.func.isRequired,
    onMobileMenuClose: PropTypes.func,
}

ServicesSection.defaultProps = {
    expandedSection: null,
    onMobileMenuClose: null,
}

export default ServicesSection
