import React, { useEffect } from "react"
import PropTypes from "prop-types"
import AOS from 'aos'
import { Link } from 'gatsby'
import 'aos/dist/aos.css'



import NavBar from "./NavBar"
import Footer from "./Footer"
import LandBotContainer from '../landBot/LandBotContainer'
import KontorLogo from '../../images/logo/logo-blue.svg'

// Context Providers
import NavProvider from '../context/NavContext'
import BotProvider from '../context/BotContext'
import BotButton from '../landBot/BotButton'


const Layout = ({ activePage, children, gutters, container, location, mainButton, noNavigation, footerAddition }) => {

    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        AOS.init();
    });

    let menuItems = [
        { name: "Featured Properties", path: "/available-properties" },
        { name: "About", path: "/about" },
        { name: "For Asset Owners", path: "/asset-owners"},
        { name: "Team", path: "/team" },
        { name: "Knowledge Hub" },
    ]
    if (searchParams.has('utm_medium') && searchParams.get('utm_medium').toLowerCase() === 'cpc') {
        menuItems = [
            { name: "About", path: "/about" },
            { name: "For Asset Owners", path: "/asset-owners"},
            { name: "Team", path: "/team" },
            { name: "Knowledge Hub" },
        ]
    }
    return (
        <NavProvider>
            <BotProvider>
                {noNavigation && (
                    <div className="flex items-center justify-center p-8 pb-0">
                        <Link className="mw-sm " to="/" state={{ fromSite: true }} style={{ width: 200 }}>
                            <img src={KontorLogo} alt="Kontor Logo" />
                        </Link>
                    </div>
                )}
                {!noNavigation && (
                    <NavBar
                        activePage={activePage}
                        location={location}
                        menuItems={menuItems}
                        mainButton={mainButton}
                    />
                )}
                <main
                    id="main"
                    style={{ minHeight: 'auto' }}
                    className={`${container ? "container" : ""} ${gutters ? 'p-6' : ''} mx-auto overflow-x-hidden overflow-y-hidden snap-parent `}>
                    {children}
                </main>

                <Footer addition={footerAddition} />
                <LandBotContainer />
            </BotProvider>
        </NavProvider>
    )
}

Layout.defaultProps = {
    gutters: true,
    container: true,
    menuActive: true,
    activePage: null,
    mainButton: (
        <BotButton
            id="h-lb-search"
            type="secondary"
            className="py-2 relative text-2xl md:text-xl shadow-charlie whitespace-no-wrap px-3 mt-8 md:mt-2"
        >
            Find your next office
        </BotButton>
    ),
    noNavigation: false,
    footerAddition: null,
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    gutters: PropTypes.bool,
    container: PropTypes.bool,
    menuActive: PropTypes.bool,
    activePage: PropTypes.string,
    mainButton: PropTypes.node.isRequired,
    noNavigation: PropTypes.bool,
    footerAddition: PropTypes.node,
}

export default Layout
